<template>
  <div>
    <a v-if="item.blueprint === 'External'" :href="item.link" target="_blank">
      <div class="archive-cover" v-html="item.cover.srcset" />
      <div class="space-y-6">
        <h2
          class="md:text-xl text-center font-semibold leading-none"
          v-html="item.display_title || item.title"
        ></h2>
        <div class="text-sm" v-html="item.abstract"></div>
      </div>
    </a>
    <router-link
      v-else
      :to="`/${$route.params.lang || 'en'}/publication/${item.slug}`"
      ><div class="archive-cover" v-html="item.cover.srcset"/>
      <div class="space-y-6">
        <h2
          class="md:text-xl text-center font-semibold leading-tight"
          v-html="item.display_title || item.title"
        ></h2>
        <!-- <div class="text-sm" v-html="item.abstract"></div></div> -->
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PreviewArchive',
  props: ['item']
}
</script>

<style lang="css" scoped>
.archive-cover {
  @apply flex items-end justify-center pb-4;
  height: 33vh;

  >>> img {
    @apply shadow-lg object-contain;
    /* max-width: 28vw;
    max-height: 20vw; */
  }
}
</style>
