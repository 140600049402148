<template>
  <div id="app" :style="themeStyles">
    <div
      id="nav"
      class="sticky top-0 left-0 right-0 z-50 font-bold group relative bg-theme"
      :style="showMenu ? themeStyles : {}"
    >
      <div
        class="
          flex
          justify-between
          items-center
          px-3
          md:px-6
          md:px-8
          py-2
          cursor-pointer
        "
        @click="showMenu = !showMenu"
      >
        <div>
          <svg
            class="h-5"
            viewBox="0 0 60 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1"
              x2="60"
              y2="1"
              :stroke="burgerColor"
              stroke-width="2"
            />
            <line
              y1="13"
              x2="60"
              y2="13"
              :stroke="burgerColor"
              stroke-width="2"
            />
            <line
              y1="25"
              x2="60"
              y2="25"
              :stroke="burgerColor"
              stroke-width="2"
            />
            <line
              y1="37"
              x2="60"
              y2="37"
              :stroke="burgerColor"
              stroke-width="2"
            />
          </svg>
        </div>
        <ul class="space-x-3 flex" @click.stop>
          <li><router-link :to="getLink('en')">EN</router-link></li>
          <li><router-link :to="getLink('de')">DE</router-link></li>
          <li>
            <router-link :to="getLink('cn')">中文</router-link>
          </li>
          <li
            v-if="theme"
            class="
              flex
              items-center
              justify-center
              border
              rounded-full
              bg-white
              text-xs
            "
            @click="overrideTheme = !overrideTheme"
            :class="{ 'line-through': overrideTheme }"
            :style="buttonTheme"
          >
            &nbsp;&nbsp;&nbsp;Abc&nbsp;&nbsp;&nbsp;
          </li>
        </ul>
      </div>

      <transition-expand>
        <div
          v-if="showMenu"
          class="px-3 md:px-8 pt-3 md:pt-6 pb-6 z-10 relative"
          :style="themeStyles"
        >
          <ul class="text-huge">
            <li>
              <router-link to="/">All Publications</router-link>
            </li>
            <li>
              <a href="https://timesartcenter.org/">Times Art Center Berlin</a>
            </li>
          </ul>
        </div>
      </transition-expand>
    </div>
    <router-view
      @theme="updateTheme"
      :themeStyles="themeStyles"
      :themeStylesWithBackground="themeStylesWithBackground"
      :themeStylesBar="themeStylesBar"
      @zoom="handleZoom"
    />
    <div
      v-if="zoomUrl"
      class="
        z-50
        fixed
        bg-white
        inset-0
        flex
        items-center
        justify-center
        cursor-move
      "
    >
      <img ref="zoom" :src="zoomUrl" class="p-6" />
    </div>
    <div
      v-if="zoomUrl"
      class="fixed top-0 left-0 right-0 flex justify-between z-50"
    >
      <div
        class="
          cursor-pointer
          m-2
          md:m-4
          z-40
          bg-white
          rounded-full
          px-4
          py-2
        "
        @click="zoomUrl = false"
      >
        Close
      </div>
      <div class="pointer-events-none m-4">
        Scroll/Pinch to Zoom
      </div>
    </div>
  </div>
</template>

<script>
import 'lazysizes'
import pz from 'panzoom'
import TransitionExpand from '@/components/TransitionExpand.vue'

export default {
  data() {
    return {
      showMenu: false,
      theme: null,
      zoomUrl: false,
      overrideTheme: false
    }
  },
  components: {
    TransitionExpand
  },
  watch: {
    $route() {
      this.showMenu = false

      if (this.$route.name === 'Home') {
        this.resetTheme()
      }
    },
    zoomUrl() {
      this.$nextTick(() => {
        pz(this.$refs.zoom, {
          minZoom: 1,
          maxZoom: 3.5
        })
      })
    },
    overrideTheme() {
      this.$nextTick(() => {
        if (this.overrideTheme) {
          document.body.style.backgroundColor = this.currentTheme.bg
          document.getElementsByTagName(
            'html'
          )[0].style.backgroundColor = this.currentTheme.bg
        } else {
          if (this.theme.type == 'image') {
            document.body.style.backgroundColor = ''
            document.getElementsByTagName('html')[0].style.backgroundColor = ''
            document.getElementsByTagName(
              'html'
            )[0].style.backgroundImage = this.theme.image
            document.getElementsByTagName(
              'html'
            )[0].style.backgroundRepeat = this.theme.repeat
          } else {
            document.body.style.backgroundColor = this.theme.bg
            document.getElementsByTagName(
              'html'
            )[0].style.backgroundColor = this.theme.bg
          }
        }
      })
    },
    theme() {
      this.$nextTick(() => {
        if (this.theme) {
          document.documentElement.style.setProperty(
            '--plyr-color-main',
            this.theme.text
          )
        }
      })
    }
  },
  methods: {
    handleZoom(url) {
      this.zoomUrl = url
    },
    getLink(lang) {
      if (this.$route.name === 'Artworks') {
        return {
          name: this.$route.name,
          params: {
            lang: lang,
            floor: this.$route.params.floor,
            artist: this.$route.params.artist,
            artistSlug: this.$route.params.artistSlug
          }
        }
      } else if (this.$route.name === 'Content') {
        return {
          name: this.$route.name,
          params: {
            lang: lang,
            contentSlug: this.$route.params.contentSlug,
            slug: this.$route.params.slug
          }
        }
      } else {
        return {
          name: this.$route.name,
          params: {
            lang: lang
          }
        }
      }
    },
    updateTheme(theme) {
      this.theme = theme

      if (this.theme.type == 'image') {
        document.getElementsByTagName(
          'html'
        )[0].style.backgroundImage = this.currentTheme.image
        document.getElementsByTagName(
          'html'
        )[0].style.backgroundRepeat = this.currentTheme.repeat
      } else {
        document.body.style.backgroundColor = this.currentTheme.bg
        document.getElementsByTagName(
          'html'
        )[0].style.backgroundColor = this.currentTheme.bg
      }
    },
    resetTheme() {
      this.theme = null
      this.overrideTheme = false
      document.body.style.backgroundColor = ''
      document.getElementsByTagName('html')[0].style.backgroundColor = ''
      document.getElementsByTagName('html')[0].style.backgroundImage = ''
    }
  },
  computed: {
    currentTheme() {
      if (this.theme && this.overrideTheme) {
        let tempTheme = { ...this.theme }
        tempTheme.bg = 'rgb(220,220,220)'

        if (this.theme.override) {
          tempTheme.text = this.theme.override
        }
        return tempTheme
      } else if (this.theme) {
        return this.theme
      } else {
        return {
          bg: 'rgb(220,220,220)',
          text: 'black'
        }
      }
    },
    burgerColor() {
      if (this.themeStyles && this.theme.text !== null) {
        return this.currentTheme.text
      } else {
        return 'black'
      }
    },
    themeStyles() {
      if (this.theme) {
        return {
          'border-color': `${this.currentTheme.text} !important`,
          color: `${this.currentTheme.text} !important`
        }
      } else {
        return false
      }
    },
    themeStylesBar() {
      if (this.theme) {
        return {
          'background-color': `${this.currentTheme.bg} !important`,
          'border-color': `${this.currentTheme.text} !important`,
          color: `${this.currentTheme.text} !important`
        }
      } else {
        return {
          'background-color': `rgb(220,220,220) !important`,
          'border-color': `black !important`,
          color: `black !important`
        }
      }
    },
    themeStylesWithBackground() {
      if (this.theme) {
        if (this.theme.type !== 'image') {
          return {
            'background-color': `${this.currentTheme.bg} !important`,
            'border-color': `${this.currentTheme.text} !important`,
            color: `${this.currentTheme.text} !important`
          }
        } else {
          return {
            'border-color': `${this.currentTheme.text} !important`,
            color: `${this.currentTheme.text} !important`
          }
        }
      } else {
        return {
          'background-color': `rgb(220,220,220) !important`,
          'border-color': `black !important`,
          color: `black !important`
        }
      }
    },
    buttonTheme() {
      if (this.overrideTheme) {
        if (this.theme.override) {
          return {
            'background-color': 'rgb(220,220,220)',
            'border-color': `${this.theme.override} !important`,
            color: `${this.theme.override} !important`
          }
        } else {
          return {
            'background-color': `${this.theme.bg} !important`,
            'border-color': `${this.theme.text} !important`,
            color: `${this.theme.text} !important`
          }
        }
      } else {
        if (this.theme.override) {
          return {
            'background-color': `${this.theme.override} !important`,
            'border-color': 'rgb(220,220,220)',
            color: 'rgb(220,220,220)'
          }
        } else {
          return {
            'background-color': 'rgb(220,220,220)',
            'border-color': `${this.theme.text} !important`,
            color: `${this.theme.text} !important`
          }
        }
      }
    }
  }
}
</script>

<style lang="css">
@import './css/app.css';

a:hover,
a.active {
  @apply underline;
}
</style>
