<template>
  <div class="home">
    <h1 class="text-massive font-semibold pl-6 mt-3 md:mt-0 hidden md:block">
      Publications
    </h1>
    <div v-if="publications" class="space-y-12">
      <publication-preview-current :item="latest" />

      <h1 class="text-xl md:text-3xl font-semibold pl-6 text-center pt-32">
        Archive
      </h1>

      <div class="grid grid-cols-2 gap-8 md:grid-cols-3 xl:grid-cols-4 px-8">
        <publication-preview-archive
          v-for="(item, count) in archive"
          :item="item"
          :key="count"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PublicationPreviewCurrent from '../components/PublicationPreviewCurrent.vue'
import PublicationPreviewArchive from '../components/PublicationPreviewArchive.vue'
// @ is an alias to /src

export default {
  components: { PublicationPreviewCurrent, PublicationPreviewArchive },
  name: 'Home',
  data() {
    return {
      publications: null
    }
  },
  computed: {
    content() {
      return this.publications[this.$route.params.lang || 'en']
    },
    latest() {
      return this.content[0]
    },
    archive() {
      return this.content.slice(1)
    }
  },
  mounted() {
    try {
      fetch(`${process.env.VUE_APP_API_URL}/v1/publications`, {
        mode: 'cors'
      }).then(data => {
        data.json().then(json => (this.publications = json))
      })
    } catch (err) {
      return err
    }
  }
}
</script>
