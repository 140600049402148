import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang?',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:lang/publication/:slug',
    name: 'Publication',
    component: () => import(/* webpackChunkName: "" */ '../views/Publication.vue'),
    children: [
      {
        path: 'artworks/:floor?/:artist?/:artistSlug?',
        name: 'Artworks',
        component: () => import(/* webpackChunkName: "" */ '../components/Artworks.vue'),
      },
      {
        path: 'content/:contentSlug?',
        name: 'Content',
        component: () => import(/* webpackChunkName: "" */ '../components/Content.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
