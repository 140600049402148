<template>
  <div>
    <div
      v-if="linkItem"
      class="flex flex-col md:flex-row px-6 md:px-8 md:space-x-8 group"
    >
      <router-link
        id="preview-cover"
        ref="cover"
        :to="`/${$route.params.lang || 'en'}/publication/${item.slug}`"
        class="cover md:w-1/2"
        v-html="item.cover.srcset"
      />
      <div class="flex-1 space-y-8">
        <router-link
          v-if="linkItem"
          :to="`/${$route.params.lang || 'en'}/publication/${item.slug}`"
        >
          <h2
            class="text-xl md:text-3xl max-w-2xl font-semibold mt-6 md:mt-0"
            v-html="item.display_title || item.title"
          ></h2
        ></router-link>
        <div
          class="
            border
            py-2
            px-3
            font-bold
            uppercase
            text-accent
            border-accent
            inline-block
            pulse
            tracking-widest
          "
        >
          <span v-if="upcoming">Upcoming</span>
          <span v-else>Current</span>
        </div>
        <div class="md:text-xl max-w-2xl" v-html="item.abstract"></div>
        <div class="transition-all duration-200 pl-0 hover:pl-8">
          <router-link
            v-if="linkItem"
            :to="`/${$route.params.lang || 'en'}/publication/${item.slug}`"
            class="button"
            ><span>> view</span></router-link
          >
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col md:flex-row px-6 md:px-8 md:space-x-8 group"
    >
      <div
        ref="cover"
        id="preview-cover"
        class="cover md:w-1/2"
        v-html="item.cover.srcset"
      />
      <div class="flex-1 space-y-8">
        <h2
          class="text-xl md:text-3xl max-w-2xl font-semibold mt-6 md:mt-0"
          v-html="item.display_title || item.title"
        ></h2>
        <div
          class="
            border
            py-2
            px-3
            font-bold
            uppercase
            text-accent
            border-accent
            inline-block
            pulse
            tracking-widest
            select-none
          "
        >
          <span v-if="upcoming">Upcoming </span>
          <span v-else>Current</span>
        </div>
        <div class="md:text-xl max-w-2xl" v-html="item.abstract"></div>
        <div class="button opacity-50 pointer-events-none">
          <span
            >> {{ format(new Date(item.publication_date), 'dd.MM.yyyy') }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBefore, parse, format } from 'date-fns'

export default {
  name: 'Preview',
  props: ['item'],
  mounted() {
    this.$nextTick(() => {
      document
        .getElementById('preview-cover')
        .querySelector('img')
        .animate(
          [
            { transform: 'rotate(-12deg) scale(0.75)' },
            { transform: 'rotate(0deg) scale(1)' }
          ],
          {
            duration: 45000,
            iterations: 1
          }
        )
    })
  },
  computed: {
    linkItem() {
      return (
        this.$props.item.publication_type == null ||
        this.$props.item.publication_type == '' ||
        this.$props.item.publication_type == 'linked' ||
        (this.$props.item.publication_type == 'unlinked' && !this.upcoming)
      )
    },
    upcoming() {
      if (this.$props.item.publication_date) {
        return isBefore(
          new Date(),
          parse(this.$props.item.publication_date, 'yyyy-MM-dd', new Date())
        )
      } else {
        return false
      }
    }
  },
  methods: {
    format
  }
}
</script>

<style lang="css" scoped>
.cover >>> img {
  @apply w-full shadow-xl;
}

.button {
  @apply block py-2;

  &:hover {
    /* @apply pl-16; */

    span {
      @apply border-accent text-accent;
    }
  }

  > span {
    @apply text-xl inline-block py-2 px-6 border border-black;
    border-radius: 100%;
  }
}
</style>
